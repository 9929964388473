.fade-in {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease, transform 1s ease;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}
